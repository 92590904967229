Soins visage:
  - name: Éclat divin
    description: Nettoyage, exfoliant, vapozone, modelage et masque
    duration: 60 min
    price: 60€

Soins du corps:
  - name: Gommage
    duration: 30 min
    price: 30€
  - name: Enveloppement
    duration: 30 min
    price: 30€
  - name: Soin du dos ou jambes
    description: Gommage, enveloppement et massage
    duration: "+/- 1h15"
    price: 75€
  - name: Rituel du corps
    description: Gommage, enveloppement et massage
    duration: "+/- 2h"
    price: 100€
  - name: Conseils
    description: Conseils soins pour pallier les effets secondaires sur la peau
    duration: "+/- 1h"
    price: 40€

Beauté des mains/pieds:
  - name: Manucure + pose vernis classique
    price: 20€ + 5€
  - name: Spa manucure + pose vernis classique
    price: 30€ + 5€
  - name: Spa pédicure + pose vernis classique
    price: 45€ + 5€
  - name: Pédicure médicale
    price: 35€

Massages relaxants:
  - name: Aux huiles essentielles (Dos)
    duration: 30 min
    price: 35€
  - name: Aux huiles essentielles (Corps)
    duration: 60 min
    price: 70€
  - name: Jambes lourdes
    duration: 30 min
    price: 40€
  - name: Massage des pieds
    description: Pour une harmonisation et une relaxation du corps
    duration: 45 min
    price: 50€

Réflexologie:
  - name: Réflexologie faciale + massage du visage/cou/épaules
    duration: 45 min
    price: 50€
  - name: Réflexologie palmaire
    duration: 30 min
    price: 35€

Maquillage:
  - name: Conseils et techniques de maquillage
    description: Pour se réapproprier son image corporelle
    price: 30€

Oncobulle:
  - name: Bulle de douceur
    description: Pure détente pour permettre à la peau du visage de retrouver tous son éclat
    duration: 60 min
    price: 45€
  - name: Mains de soie/Pieds légers, Ongles parfaits
    description: soin apaisant et nourrissant des mains/pieds et des ongles
    duration: 30 min
    price: 25€
  - name: Evasion douceur
    description: Modelage relaxant du corps qui allie le soin de la peau et la détente des muscles et de l'esprit
    duration: 60 min
    price: 50€
  - name: Je suis belle
    description: Conseils en maquillage et soins
    price: 20€
